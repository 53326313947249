div#renderer-container > canvas {
  outline:none;
}
div#renderer-container > canvas.mouseup {
  cursor: grab;
}
div#renderer-container > canvas.mousedown {
  cursor: grabbing;
}
div#renderer-container > canvas.mousepoint {
  cursor: pointer;
}
p#info{
  position: fixed;
  top: 3vh;
  left:16px;
  right:16px;
  text-align: center;
  opacity: 0.5;
}